import React, { useRef } from 'react';
import {
  FiArrowLeft,
  FiCreditCard,
  FiLink,
  FiPercent,
  FiShoppingCart,
} from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';

import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { createProduct } from '../../../../services/requests/Finance/createProduct';
import { useToast } from '../../../../hooks/toast';
import { CustomerFinance } from '../../../../services/requests/Finance/getCustomer';

export interface commissionValue {
  unitComission: number;
  headquarterComission: number;
}
export interface commissions {
  comissionType: string;
  commissionValue: commissionValue;
}
export function ResumeCheckoutModal({
  isShown,
  setIsShown,
  data,
  setIsOpenResume,
  bankResponse,
  valuesBank,
  isMatriz,
  isRoyalties,
  isVariableValue,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data: any;
  setIsOpenResume: (value: React.SetStateAction<boolean>) => void;
  bankResponse: { data: CustomerFinance } | undefined;
  valuesBank: any;
  isMatriz?: boolean | undefined;
  isRoyalties?: boolean | undefined;
  isVariableValue?: boolean | undefined;
}) {
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();

  async function handleSubmit() {
    const numberedValue = Number(data.value.toString().replace(',', '.'));
    const comissionUnit = Number(
      data?.commissions?.commissionValue?.unitComission,
    );
    const comissionHeadquarters = Number(
      data?.commissions?.commissionValue?.headquarterComission,
    );

    const unitComissionTratative = isVariableValue
      ? comissionUnit
      : comissionUnit * 100;

    const headquartersComissionTratative =
      isVariableValue === false
        ? comissionHeadquarters * 100
        : comissionHeadquarters;

    const dataToSend = {
      ...data,
      value: numberedValue * 100,
      commissions: {
        ...data.commissions,
        commissionValue: {
          ...data.commissionValue,
          unitComission: isMatriz === true ? undefined : unitComissionTratative,
          ...data.comissionValue,
          headquarterComission:
            isRoyalties === true ? undefined : headquartersComissionTratative,
        },
      },
    };

    const productCreated = await createProduct(dataToSend);

    !!productCreated &&
      addToast({
        title: 'Sucesso',
        description: 'Produto criado com sucesso',
        type: 'success',
      });

    !productCreated &&
      addToast({
        title: 'error',
        description: 'Ocorreu um erro na criação deste produto.',
        type: 'error',
      });

    sendFormRef.current?.click();

    !!productCreated && setIsOpenResume(false);
  }

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <S.ModalWrapper>
          <S.ModalHeader>
            <Headline variant="h4">Resumo do checkout</Headline>
            <button
              type="button"
              onClick={() => setIsOpenResume(false)}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.ModalHeader>
          <S.ModalBody>
            <S.SectionResume>
              <Headline variant="h5">
                <FiShoppingCart />
                Produto
              </Headline>
              <p>Nome do produto: {data.name}</p>
              <p>Descrição: {data.description}</p>
              <p>Valor: R${data.value}</p>
              <p>
                Produto físico:{' '}
                {data?.physicalProduct === false ? 'Não' : 'Sim'}
              </p>
            </S.SectionResume>
            <S.SectionResume>
              <Headline variant="h5">
                <FiCreditCard />
                Dados bancários do cliente.
              </Headline>
              <p>Banco: {bankResponse?.data?.bank || valuesBank?.data?.bank}</p>

              <p>
                Número da conta:{' '}
                {bankResponse?.data?.bankCc || valuesBank?.bankCc}
              </p>
              <p>
                Agência:{' '}
                {bankResponse?.data?.bankAgency || valuesBank?.bankAgency}
              </p>
              <p>
                Tipo de conta:{' '}
                {bankResponse?.data?.accountType || valuesBank?.accountType}
              </p>
            </S.SectionResume>{' '}
            <S.SectionResume>
              <Headline variant="h5">
                <FiPercent />
                Porcentagem de comissão
              </Headline>
              <p>
                Quem irá receber a porcentagem de comissão:{' '}
                {isMatriz === true ? 'Matriz' : 'Matriz e franquia'}
              </p>
              <p>
                Forma de comissionamento do valor:{' '}
                {isVariableValue === true ? 'Valor variável' : 'Valor fixo'}
              </p>
              <p>
                A matriz receberá o valor em royalties:{' '}
                {isRoyalties ? 'Sim' : 'Não'}
              </p>
            </S.SectionResume>
            <S.SectionResume>
              <Headline variant="h5">
                <FiCreditCard />
                Formas de pagamento
              </Headline>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {data.payableWith.map((productLists: any) => {
                  switch (productLists) {
                    case 'credit_card':
                      return ` - Cartão ${data?.creditCardInstallments}x`;
                    case 'bank_slip':
                      return ' - Boleto';
                    case 'pix':
                      return ' - Pix';
                    default:
                      break;
                  }
                  return <p>{productLists}</p>;
                })}
              </div>
              {/* <p>Boleto</p>
              <p>Cartão 12x</p> */}
            </S.SectionResume>
          </S.ModalBody>
          <S.ModalFooter>
            <Button
              variant="primary"
              color="green"
              size="default"
              onClick={handleSubmit}
            >
              Gerar link
              <FiLink color="#333333" />
            </Button>
            <Button
              variant="secondary"
              color="green"
              size="default"
              onClick={() => {
                setIsOpenResume(false);
                setIsShown(true);
              }}
            >
              <FiArrowLeft color="#00C49A" />
              Voltar para edição
            </Button>
          </S.ModalFooter>
        </S.ModalWrapper>
      </Modal>
    </>
  );
}
